import Styled, { css } from 'styled-components';

import { colors, breakpoints, fonts } from '../../styles/variables';
import { H2, H3 } from '../../styles/commonStyles';

export const TabPanel = Styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 5rem;
  padding: 0 2rem;

  @media ${breakpoints.tabletLandscape} {
    padding: 0 5rem;
  }
`;

export const Tab = Styled.li`
  font-size: 1.6rem;
  font-family: ${fonts.merriweatherLight};
  color: ${colors.darkAccent};
  text-transform: uppercase;
  margin: 0 .8rem;
  white-space: nowrap;
  padding: 1rem 1.5rem;
  border: .2rem solid ${colors.darkAccent};
  background-color: ${colors.lightShade};
  ${({ active }) =>
    active && `background-color: ${colors.darkAccent}; color: ${colors.lightShade};`};
  transition: all .2s ease-in-out;
  transition-property: color, background-color;
  cursor: pointer;

  :hover {
    background-color: ${colors.darkAccent};
    color: ${colors.lightShade};
  }
`;

export const TabList = Styled.ul`
  display: flex;
  margin-top: -2rem;
  width: 100%;
  overflow: auto;
  z-index: 1;

  @media ${breakpoints.desktop} {
    justify-content: center;
  }
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const MenuItem = Styled.div`
  text-align: center;
  margin: .5rem 0 2rem;
  font-family: ${fonts.montserratRegular};
  word-break: break-word;
`;

export const Category = Styled(H2)`
  font-family: ${fonts.merriweatherBold};
  color: ${colors.darkAccent};
  text-transform: uppercase;
  letter-spacing: .5rem;
  margin-bottom: 4rem;
`;

const TextSizes = css`
  font-size: 1.4rem;

  @media ${breakpoints.phone} {
    font-size: 1.6rem;
  }

  @media ${breakpoints.tabletPortrait} {
    font-size: 1.6rem;
  }

  @media ${breakpoints.tabletLandscape} {
    font-size: 1.6rem;
  }

  @media ${breakpoints.desktop} {
    font-size: 1.8rem;
  }

  @media ${breakpoints.desktopL} {
    font-size: 2rem;
  }
`;

export const Product = Styled(H3)`
  font-family: ${fonts.merriweatherBold};
  color: ${colors.darkAccent};
  letter-spacing: .3rem;
  margin-bottom: 1rem;
  text-transform: none;

  font-size: 1.6rem;

  @media ${breakpoints.phone} {
    font-size: 1.8rem;
  }
  @media ${breakpoints.tabletPortrait} {
    font-size: 1.8rem;
  }

  @media ${breakpoints.tabletLandscape} {
    font-size: 1.8rem;
  }

  @media ${breakpoints.desktop} {
    font-size: 2rem;
  }

  @media ${breakpoints.desktopL} {
    font-size: 2.2rem;
  }
`;

export const Description = Styled.p`
  ${TextSizes}
  margin: .3rem 0;
`;

export const Separator = Styled.img`
  width: 25rem;
  margin: 0 auto;
  padding: 3rem 0 6rem;
`;

export const Pipe = Styled.span`
  ${TextSizes}

  color: ${colors.darkAccent};
  font-family: ${fonts.montserratRegular};
  margin: 0 1rem;

  ::before {
    content: '|'
  }
`;
