import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  TabList,
  TabPanel,
  Tab,
  MenuItem,
  Description,
  Separator,
  Category,
  Product,
  Pipe,
} from './Tabs.style';
import Container from '../Container/Container';

const propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string.isRequired,
      products: PropTypes.arrayOf(
        PropTypes.shape({
          calories: PropTypes.string,
          description: PropTypes.string,
          name: PropTypes.string.isRequired,
          price: PropTypes.string.isRequired,
          quantity: PropTypes.string,
          unit: PropTypes.string,
        }),
      ),
    }).isRequired,
  ).isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleTabChange: PropTypes.func.isRequired,
  currentTab: PropTypes.string.isRequired,
};

const TabsWrapper = ({ items, categories, handleTabChange, currentTab }) => {
  const tabContainer = useRef(null);

  return (
    <Container flexStart>
      <TabList ref={tabContainer}>
        {categories.map((category, index) => (
          <Tab
            key={category}
            onClick={() => handleTabChange(category)}
            active={category === currentTab}
            tabindex={index}
          >
            {category}
          </Tab>
        ))}
      </TabList>
      <TabPanel>
        {items.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={index}>
            <Category>{item.category}</Category>
            {item.products &&
              item.products.map(({ name, description, quantity, unit, price, calories }, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <MenuItem key={i}>
                  <Product>{name}</Product>
                  <Description>
                    {description && (
                      <>
                        {description}
                        <Pipe />
                      </>
                    )}
                    {quantity && (
                      <>
                        {quantity} {unit}
                        <Pipe />
                      </>
                    )}
                    {calories && (
                      <>
                        {calories} kcal
                        <Pipe />
                      </>
                    )}
                    {price && (
                      <>
                        {Number(price) === Math.floor(Number(price))
                          ? price
                          : Number(price).toFixed(2)}{' '}
                        PLN
                      </>
                    )}
                  </Description>
                </MenuItem>
              ))}
            <Separator alt="separator" src="/img/separator.svg" />
          </Fragment>
        ))}
      </TabPanel>
    </Container>
  );
};

TabsWrapper.propTypes = propTypes;

export default TabsWrapper;
